function getContentCanvasPosition({
  rotation,
  mirror,
  videoWidth,
  canvasWidth,
}) {
  if (rotation) {
    return { dx: 0, dy: 0 };
  }

  return {
    dx: mirror
      ? -canvasWidth - videoWidth / 2 + canvasWidth / 2
      : -videoWidth / 2 + canvasWidth / 2,
    dy: 0,
  };
}

function getContentCanvasDimensions({ rotation, canvasSize, videoWidth }) {
  if (rotation) {
    return { width: canvasSize.height, height: canvasSize.width };
  }

  return { width: videoWidth, height: canvasSize.height };
}

export function getCanvasAttributes({
  rotation,
  mirror,
  canvasSize,
  videoSize,
}) {
  const { dx, dy } = getContentCanvasPosition({
    rotation,
    mirror,
    videoWidth: videoSize.width,
    canvasWidth: canvasSize.width,
  });
  const { width, height } = getContentCanvasDimensions({
    rotation,
    canvasSize,
    videoWidth: videoSize.width,
  });

  return [dx, dy, width, height];
}

export function getLaserStyles(
  resizedDetection,
  rotation,
  mirror,
  canvasWidth
) {
  const left = resizedDetection.box.left;
  const top = resizedDetection.box.top;
  const width = resizedDetection.box.width;
  const height = resizedDetection.box.height;
  const topCorrection = 150;
  const topCorrctionRotated = 100;

  if (rotation)
    return {
      left: mirror ? canvasWidth - width - left : left,
      top: top - topCorrctionRotated,
      width,
      height,
    };

  return {
    left,
    top: top - topCorrection,
    width,
    height,
  };
}
