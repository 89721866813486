/** @jsxImportSource @emotion/react */
import { useEffect, useRef, useCallback, useState } from "react";
import { startCameraVideo } from "helpers";
import { getStyles } from "helpers/cameraFilterInput";
import { getCanvasAttributes, getLaserStyles } from "helpers/faceTracker";
import * as faceapi from "face-api.js";
import { Laser } from "assets/prey";
import { useLogger } from "providers/LoggerProvider"

import "./FaceTrackerElement.css";

function FaceTrackerElement({ element, player = {} }) {
  const [laserStyles, setLaserStyles] = useState({ display: "none" });
  const videoRef = useRef();
  const canvasRef = useRef();
  const { sendLog } = useLogger();
  const { style, config } = element;
  const { mirror, rotation } = config;

  const tracking = useCallback(
    async (video, canvas) => {
      const videoSize = { width: video.scrollWidth, height: style.height };
      const canvasSize = { width: style.width, height: style.height };
      const displaySize = rotation
        ? { width: style.width, height: style.height }
        : { width: videoSize.width, height: style.height };
      const ctx = canvas.getContext("2d");

      faceapi.matchDimensions(canvas, displaySize);

      setInterval(async () => {
        if (rotation) {
          ctx.setTransform(1, 0, 0, 1, 0, 0);

          ctx.translate(canvas.width / 2, canvas.height / 2);
          ctx.rotate((rotation * Math.PI) / 180);
          ctx.translate(-canvas.height / 2, -canvas.width / 2);
          if (mirror) {
            ctx.save();
            ctx.scale(-1, 1);
            ctx.restore();
          }
        }

        if (!rotation && mirror) {
          ctx.setTransform(1, 0, 0, 1, 0, 0);
          ctx.scale(-1, 1);
        }

        ctx.drawImage(
          video,
          ...getCanvasAttributes({ rotation, mirror, canvasSize, videoSize })
        );

        const detection = await faceapi.detectSingleFace(
          canvas,
          new faceapi.TinyFaceDetectorOptions({
            scoreThreshold: 0.3,
            inputSize: 800
          })
        );

        if (detection) {
          const resizedDetection = faceapi.resizeResults(
            detection,
            displaySize
          );

          setLaserStyles(
            getLaserStyles(resizedDetection, rotation, mirror, canvasSize.width)
          );

          try {
            sendLog(`Face detected ${JSON.stringify(detection)} on player ${player.bsPlayerId}`)
          }catch (error) {
            sendLog(`Error parsing face detection`)
          }

        } else {
          setLaserStyles({ display: "none" });
        }
      }, 1200);
    },
    [mirror, rotation, style]
  );

  async function loadFaceApiModels() {
    await faceapi.nets.tinyFaceDetector.loadFromUri("/face-api/models");
    await faceapi.nets.faceLandmark68Net.loadFromUri("/face-api/models");
    await faceapi.nets.faceRecognitionNet.loadFromUri("/face-api/models");
    await faceapi.nets.faceExpressionNet.loadFromUri("/face-api/models");
  }

  const init = useCallback(async () => {
    const video = videoRef.current;
    const canvas = canvasRef.current;

    await loadFaceApiModels();
    await startCameraVideo(video);
    await tracking(video, canvas);
  }, [tracking]);

  useEffect(() => {
    init();
  }, [init]);

  return (
    <div style={style}>
      <video
        className="face-tracker-video"
        ref={videoRef}
        style={getStyles(
          rotation,
          mirror,
          style,
          videoRef.current?.scrollWidth
        )}
      />
      {/* Not displayed, just to calculate laser position */}
      <canvas
        className="face-tracker-canvas "
        ref={canvasRef}
        width={videoRef.current?.scrollWidth}
        height={style.height}
        style={{ display: "none" }}
      />
      <img src={Laser} alt="laser" className="laser" style={laserStyles} />
    </div>
  );
}

export default FaceTrackerElement;
