/** @jsxImportSource @emotion/react */
import { useEffect, useRef, useCallback } from "react";
import { startCameraVideo } from "helpers";
import * as faceapi from "face-api.js";
import { useLogger } from "providers/LoggerProvider"

function FaceDetectionElement({ element, player = {}, onAction, isVisible }) {
  const videoRef = useRef();
  const { sendLog } = useLogger();
  const { style } = element;
  const intervalId = useRef();

  const tracking = useCallback(
    async (video) => {

      if (!isVisible) {
        clearInterval(intervalId.current);
        intervalId.current = null;
        return;
      }

      if (intervalId.current) {
        return;
      }

      intervalId.current = setInterval(async () => {

        const detection = await faceapi.detectSingleFace(
          video,
          new faceapi.TinyFaceDetectorOptions({
            scoreThreshold: 0.3,
            inputSize: 800
          })
        );

        if (detection) {
          isVisible && onAction()

          try {
            sendLog(`Face detected ${JSON.stringify(detection)} on player ${player.bsPlayerId}`)
          }catch (error) {
            sendLog(`Error parsing face detection`)
          }
        } else {
          sendLog(`No face detected`)
        }
      }, 3000);
    },
    [onAction, player.bsPlayerId, sendLog, isVisible]
  );

  async function loadFaceApiModels() {
    await faceapi.nets.tinyFaceDetector.loadFromUri("/face-api/models");
    await faceapi.nets.faceLandmark68Net.loadFromUri("/face-api/models");
    await faceapi.nets.faceRecognitionNet.loadFromUri("/face-api/models");
    await faceapi.nets.faceExpressionNet.loadFromUri("/face-api/models");
  }

  const init = useCallback(async () => {
    const video = videoRef.current;

    await loadFaceApiModels();
    await startCameraVideo(video);
    await tracking(video);
  }, [tracking]);

  useEffect(() => {
    init();
  }, [init]);

  return (
    <div style={style}>
      <video
        ref={videoRef}
        style={style}
      />
    </div>
  );
}

export default FaceDetectionElement;
