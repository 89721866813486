function getTranslation(rotation, mirror, videoWidth, canvasWidth) {
  if (rotation === 90) {
    return mirror ? { dx: 350, dy: -350 } : { dx: 350, dy: 350 };
  }

  if (rotation === -90) {
    return mirror ? { dx: -350, dy: 350 } : { dx: -350, dy: -350 };
  }

  const dx = videoWidth / 2 - canvasWidth / 2;
  return mirror ? { dx, dy: 0 } : { dx: -dx, dy: 0 };
}

function getTransform(rotation, mirror, videoWidth, canvasWidth) {
  const { dx, dy } = getTranslation(rotation, mirror, videoWidth, canvasWidth);

  const scale = mirror ? "scale(-1.2, 1.2)" : "scale(1.2)";
  const rotate = `rotate(${rotation}deg)`;
  const translate = `translate(${dx}px, ${dy}px)`;

  return `${scale} ${rotate} ${translate}`;
}

function getDimensions(rotation, style, videoWidth) {
  if (rotation === 90 || rotation === -90) {
    return { width: style.height, height: style.width };
  }

  return { width: videoWidth, height: style.height };
}

export function getStyles(rotation = -90, mirror = true, style, videoWidth) {
  const { width, height } = getDimensions(rotation, style, videoWidth);

  return {
    width,
    height,
    transform: getTransform(rotation, mirror, width, style.width),
  };
}
